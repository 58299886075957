import { gql, useMutation } from "@apollo/client";
import React, { FC, useEffect, useMemo, useState } from "react";
import CurrencyFormat from "react-currency-format";

import { captureMessage } from "@/helpers/captureError";
import clockBig from "@/style/img/clockBig.svg";
import warningIcon from "@/style/img/warning.svg";
import { useUserData } from "@/zustand/useSettingsStore";

import Step2Row, { InvoiceLine } from "./InvoiceTableStep2Row";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  workOrderId: string;
  waitingReview: boolean;
  errorMessage: string;
  invoiceLines: any[];
  mcoNumber: string;
  mcoLineNumber: string;
  mcoLineStatus: string;
  goToStep: (arg0: number) => void;
};

const APPROVE_MCO = gql`
  mutation approveMaintenanceCustomerOrderLine(
    $maintenanceCustomerOrderNumber: String!
    $maintenanceCustomerOrderLineNumber: String!
    $issuedBy: String!
  ) {
    approveMaintenanceCustomerOrderLine(
      maintenanceCustomerOrderNumber: $maintenanceCustomerOrderNumber
      maintenanceCustomerOrderLineNumber: $maintenanceCustomerOrderLineNumber
      issuedBy: $issuedBy
    )
  }
`;

const InvoiceTableStep2: FC<Props> = ({
  workOrderId,
  goToStep,
  invoiceLines,
  mcoLineStatus: serverMcoLineStatus, // Renamed for clarity
  mcoNumber,
  mcoLineNumber,
  waitingReview,
  errorMessage,
}) => {
  const [approveMCO] = useMutation(APPROVE_MCO);
  // Needed to achive an instant UI changes, without waiting for the server to deliver the changes on mcoLineStatus
  const [localMcoLineStatus, setLocalMcoLineStatus] =
    useState<string>(serverMcoLineStatus);
  const userData = useUserData();

  // Sync local status with server status when server status is ORDER_CLOSED
  useEffect(() => {
    if (serverMcoLineStatus === "ORDER_CLOSED") {
      setLocalMcoLineStatus(serverMcoLineStatus);
    }
  }, [serverMcoLineStatus]);

  const handleInvoiceLineEdit = () => {
    // Set the status to ORDER_NOT_CLOSED when a field in Step2Row is edited. (ORDER_NOT_CLOSED is not a real server status)
    if (localMcoLineStatus !== "ORDER_NOT_CLOSED") {
      setLocalMcoLineStatus("ORDER_NOT_CLOSED");
    }
  };

  const handleMCOApproval = async () => {
    const { data } = await approveMCO({
      variables: {
        maintenanceCustomerOrderNumber: mcoNumber,
        maintenanceCustomerOrderLineNumber: mcoLineNumber,
        issuedBy: userData.mail,
      },
    });
    if (data?.approveMaintenanceCustomerOrderLine !== true) {
      console.error(workOrderId, data);
      captureMessage(
        `error approving invoice (step2) of wo: ${workOrderId} | ${data?.approveMaintenanceCustomerOrderLine}`,
      );
    }
    goToStep(3);
  };

  const sortedInvoiceLines = useMemo(() => {
    // Check if orderLines is iterable
    if (!Array.isArray(invoiceLines)) {
      // Return an empty array or handle the case as appropriate
      return [];
    }

    // Sort the operation lines using the custom comparator
    // Create a new array from orderLines because sometimes could complain about "read-only" array
    const sortedLines = [...invoiceLines].sort((a, b) => {
      if (a.itemName < b.itemName) {
        return -1;
      }
      if (a.itemName > b.itemName) {
        return 1;
      }
      // If itemName is the same, sort by id
      return a.id.localeCompare(b.id);
    });
    console.debug("step 2 invoice lines", sortedLines);
    return sortedLines;
  }, [invoiceLines]);

  return (
    <div className={styles.tableStep1}>
      {waitingReview && (
        <div className={styles.overlayLoading}>
          <img src={clockBig} alt="loading" />
          <span>Syncing invoice data with M3</span>
          <div />
        </div>
      )}
      {errorMessage && (
        <div className={`${styles.warningArea}`}>
          <img
            src={warningIcon}
            alt="Warning"
            style={{
              margin: "4px 15px -5px 3px",
              color: "black",
              borderColor: "#00ADBB",
            }}
          />
          {errorMessage}
        </div>
      )}
      <div className={styles.section}>
        <div className={`${styles.gridRow2} ${styles.headers}`}>
          <div style={{ marginLeft: "15px" }}>Operation</div>
          <div>Description</div>
          <div>Transaction date</div>
          <div>Quantity</div>
          <div>Price per unit</div>
          <div>Sales price</div>
          <div>Discount percentage</div>
          <div>Total sales price</div>
        </div>
      </div>
      {sortedInvoiceLines.map((line) => {
        return (
          <Step2Row
            key={line.id}
            invoiceLine={line as InvoiceLine}
            handleInvoiceLineEdit={handleInvoiceLineEdit}
          />
        );
      })}

      <div className={`${styles.totalSection}`}>
        <CurrencyFormat
          value={sortedInvoiceLines
            .reduce((accumulator, line) => {
              return accumulator + line.totalSalesPrice;
            }, 0)
            .toFixed(2)}
          displayType="text"
          thousandSeparator
          prefix="NOK "
          renderText={(value) => <div style={{ paddingTop: 12 }}>{value}</div>}
        />
      </div>
      <br />
      <div className={`${styles.wizardButtonSection}`}>
        <div />
        {/* <button
          className={`${textStyles.primaryText} ${styles.invoiceWizardButton} `}
          style={{
            backgroundColor: "#F6F6F6",
            borderColor: "#F6F6F6",
          }}
          type="button"
          onClick={() => goToStep(1)}
          title="Back to Review Order"
        >
          Back to Review Order👈
        </button> */}
        <button
          className={`${textStyles.primaryText} ${styles.invoiceWizardButton} ${styles.invoiceWizardButton_gold} `}
          type="button"
          disabled={localMcoLineStatus !== "ORDER_CLOSED"}
          onClick={() => handleMCOApproval()}
          title="Approve Invoice"
        >
          Approve Invoice
          {localMcoLineStatus === "ORDER_CLOSED" ? (
            <span className={` ${styles.icon}`}>👈🤑</span>
          ) : (
            <span className={` ${styles.icon}`}>...</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default InvoiceTableStep2;
