import { gql, useMutation, useQuery } from "@apollo/client";
import React, { FC, memo, useState } from "react";

import AutoCompleteInput from "@/components/common/formElements/autocompleteInput";
import InputText from "@/components/common/formElements/inputText";
import { useUserData } from "@/zustand/useSettingsStore";

import styles from "./index.module.scss";
import buttons from "@/style/buttons.module.scss";

const GET_ITEMS = gql`
  query getItems {
    items {
      id
      name
      status
      type
    }
  }
`;

const ADD_MATERIAL_ITEMS = gql`
  mutation addWorkOrderMaterials(
    $issuedBy: String!
    $amount: Int!
    $itemNumber: String!
    $quantity: Float!
    $workOrderNumber: String!
  ) {
    addWorkOrderMaterials(
      issuedBy: $issuedBy
      amount: $amount
      itemNumber: $itemNumber
      quantity: $quantity
      workOrderNumber: $workOrderNumber
    )
  }
`;

const NewMaterialItem: FC<{ workOrderId: string }> = ({ workOrderId }) => {
  const userData = useUserData();

  const [addMaterialItem] = useMutation(ADD_MATERIAL_ITEMS);
  const [hasGraphQLError, setHasGraphQLError] = useState<boolean>(false);
  const { data } = useQuery(GET_ITEMS);

  const [searchKeyword, setSearchKeyword] = useState("");
  // This is temporal, while editing. But always we want the fresh source-of-truth data it comes from data.quantity rendered
  const [materialLines, setMaterialLines] = useState<string>("1");
  const [quantity, setQuantity] = useState<string>("1");
  const [chosenMaterial, setChosenMaterial] = useState<any>(undefined);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const createMaterialItem = async (
    itemNumber: string,
    qty: string,
    amount: string,
  ) => {
    const response = await addMaterialItem({
      variables: {
        issuedBy: userData.mail,
        amount: parseInt(amount, 10),
        itemNumber,
        quantity: parseInt(qty, 10),
        workOrderNumber: workOrderId,
      },
    });

    if (response?.data?.addWorkOrderMaterial === false) {
      setHasGraphQLError(true);
      setTimeout(() => {
        setHasGraphQLError(false);
      }, 3000);
    }
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,

    chosenItem: any,
  ) => {
    const containerId =
      e.type === "change"
        ? (e.target as HTMLElement).id
        : (e.target as HTMLButtonElement).parentElement?.id;

    const value =
      e.type === "change"
        ? (e.target as HTMLInputElement).value
        : (e.target as HTMLButtonElement).innerHTML;

    if (containerId === "New Material Item") {
      // setErrors({ ...errors, customerName: false });
      setSearchKeyword(value);

      if (chosenItem) {
        setChosenMaterial({ id: chosenItem.id, value });
      }
    }
  };

  const handleSubmit = () => {
    if (chosenMaterial?.id) {
      createMaterialItem(chosenMaterial.id, quantity, materialLines);
      setMaterialLines("1");
      setQuantity("1");
      setSearchKeyword("");
      // Reset the autcomplete input
      setChosenMaterial({ id: undefined, value: "" });
      setTimeout(() => {
        setChosenMaterial(undefined);
      }, 100);
    }
  };

  const handleUpdateLinesAmount = async (e) => {
    const value = e?.target?.value || "";
    // Replace commas with dots and remove any non-numeric characters except for the dot
    const sanitizedValue = value.replace(",", ".").replace(/[^0-9.]/g, "");

    setMaterialLines(sanitizedValue);
  };

  const handleUpdateQuantity = async (e) => {
    const value = e?.target?.value || "";
    // Replace commas with dots and remove any non-numeric characters except for the dot
    const sanitizedValue = value.replace(",", ".").replace(/[^0-9.]/g, "");

    setQuantity(sanitizedValue);
  };

  const allItemsNonDuplicates = Array.from(
    new Set(data?.items?.map((item) => item.id)),
  ).map((id) => {
    return data?.items?.find((item) => item.id === id);
  });

  function filterObjects(): any[] {
    return allItemsNonDuplicates.filter((obj) =>
      obj.name.toLowerCase().includes(searchKeyword.toLowerCase()),
    );
  }

  // this is a customer requirement:

  // When the NorSea Planning Board user adds items, sometimes they need to add multiple lines of the same item.
  // For example, if a customer is renting 12 trailers on the same day, they need to add 12 of the same item number with 1 day each.

  // Change request:
  // When adding an item to WO, can we add in an input box where the user can state how many item lines of this item to add (defaulted as one)? Also, what the qty of the item should be (default one)?
  // Note that when adding multiple item lines, the quantity is the same. Example: If a customer rents 5 trailers for 3 days, then 5 trailers (5 item lines) with a qty of 3 days each. If they must change the qty of one of them, then they can do it once the lines are added.

  const finalFilteredList = filterObjects();

  // Replace commas with dots and remove any non-numeric characters except for the dot
  const materialLinesAmount = materialLines
    ? materialLines.replace(",", ".").replace(/[^0-9.]/g, "")
    : "";

  return (
    <div className={`${styles.newMaterialItem} ${styles.materialItem}`}>
      <div className={styles.addNewMaterialItem}>
        <InputText
          className={`${styles.inputAmount}`}
          value={materialLinesAmount}
          onBlur={handleUpdateLinesAmount}
          setValue={setMaterialLines}
          placeholder=""
          error={false}
          errorMessage=""
        />
        <span style={{ margin: "16px 5px 5px 5px" }}>&times;</span>
        <AutoCompleteInput
          value={chosenMaterial?.value}
          setValue={(e, item) => handleInputChange(e, item)}
          placeholder="New Material Item"
          error={false}
          errorMessage=""
          onBlur={(e) => {
            // console.log(e);
          }}
          autoCompleteValues={finalFilteredList}
        />
        <InputText
          className={`${styles.inputText} `}
          value={quantity}
          onBlur={handleUpdateQuantity}
          setValue={setQuantity}
          placeholder=""
          error={false}
          errorMessage=""
        />
        <button
          className={buttons.modalButton}
          style={{ color: chosenMaterial ? "#00adbb" : "grey" }}
          type="button"
          onClick={handleSubmit}
          disabled={!chosenMaterial}
        >
          ADD
        </button>
      </div>

      {hasGraphQLError ? (
        <div style={{ color: "#ff6344", width: "100%" }}>error, try again</div>
      ) : (
        ""
      )}
    </div>
  );
};
export default memo(NewMaterialItem);
