import { gql, useQuery } from "@apollo/client";
import { Moment } from "moment-timezone";
import React, { FC, memo, useState } from "react";

import DateAndTimeField from "@/components/common/formElements/dateAndTimeField";
import ArrowSVG from "@/components/common/formElements/multiselect/arrow";
import PopUp from "@/components/common/formElements/popUp";

import styles from "./index.module.scss";
import otherStyles from "@/components/timeline/sidePanel/sidePanel.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  handleStartWorkOrderLine: (startDate: string) => void;
  handleStopWorkOrderLine: (startDate: string) => void;
  handleStartDisturbance: (
    startDate: string,
    selectedDisturbanceCode: string,
  ) => void;
  handleStopDisturbance: (
    startDate: string,
    selectedDisturbanceCode: string,
  ) => void;
  isWOstarted: boolean;
  setDisturbanceEnabled: (value: boolean) => void;
};

const GET_DISTURBANCES_CODES = gql`
  query getDisturbanceCodes {
    disturbances {
      name
      description
      id
    }
  }
`;

const OperationLineActions: FC<Props> = ({
  handleStartWorkOrderLine,
  handleStopWorkOrderLine,
  handleStartDisturbance,
  handleStopDisturbance,
  isWOstarted,
  setDisturbanceEnabled,
}) => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [stopDate, setStopDate] = useState<Moment | null>(null);

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [selectedDisturbanceCode, setSelectedDisturbanceCode] =
    useState(undefined);
  const [disturbanceCodes, setDisturbanceCodes] = useState<[]>([]);

  const renderDisturbanceCodes = () => {
    return (
      <div className={styles.workCenterList}>
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => null}
          onClick={() => {
            setSelectedDisturbanceCode(undefined);
            setDisturbanceEnabled(false);
          }}
          key="no-disturbance"
        >
          <span
            title="No disturbance"
            className={textStyles.primaryText}
            style={{
              borderBottom: "1px dotted black",
              width: "100%",
              paddingBottom: "10px",
            }}
          >
            No disturbance
          </span>
        </div>
        {disturbanceCodes?.map((disturbance: any) => (
          <div
            tabIndex={0}
            role="button"
            onKeyDown={() => null}
            onClick={() => {
              setSelectedDisturbanceCode(disturbance?.id);
              setDisturbanceEnabled(true);
              setIsPopUpOpen(false);
            }}
            key={disturbance.name}
          >
            <span
              title={disturbance.description}
              className={textStyles.primaryText}
            >
              {disturbance.description}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const { loading } = useQuery(GET_DISTURBANCES_CODES, {
    variables: {},
    onCompleted: (res) => {
      setDisturbanceCodes(res?.disturbances);
      window.disturbanceCodes = res?.disturbances;
    },
  });
  const handleSetStartDate = (date: Moment | null) => {
    setStartDate(date);
  };
  const handleSetStopDate = (date: Moment | null) => {
    setStopDate(date);
  };

  // There is no reason to validate it this way, because you might be editing previous timestamps (One line can have multiple start/stop times)
  // const isCorrectDate = useMemo(() => {
  //   if (startDate && stopDate) {
  //     return validateStartBeforeStop(startDate, stopDate);
  //   }
  //   if (!startDate || !stopDate) {
  //     return true;
  //   }
  //   return false;
  // }, [startDate, stopDate]);

  const handleStart = () => {
    if (startDate) {
      if (selectedDisturbanceCode) {
        handleStartDisturbance(
          startDate.toISOString(),
          selectedDisturbanceCode,
        );
      } else {
        handleStartWorkOrderLine(startDate.toISOString());
      }
    }
  };

  const handleStop = () => {
    if (stopDate) {
      if (selectedDisturbanceCode) {
        handleStopDisturbance(stopDate.toISOString(), selectedDisturbanceCode);
      } else {
        handleStopWorkOrderLine(stopDate.toISOString());
      }
    }
  };

  return (
    <div>
      <div>
        {isWOstarted && (
          <PopUp
            position="right top"
            isOpen={isPopUpOpen}
            onOpen={() => setIsPopUpOpen(true)}
            onClose={() => setIsPopUpOpen(false)}
            trigger={
              <button
                className={styles.disturbanceButton}
                type="button"
                onClick={() => setIsPopUpOpen(true)}
                style={
                  selectedDisturbanceCode
                    ? {
                        color: "black",
                      }
                    : {
                        backgroundColor: "#fff",
                      }
                }
              >
                {disturbanceCodes?.find((d) => d.id === selectedDisturbanceCode)
                  ?.description || "Disturbance? (optional)"}
                <ArrowSVG />
              </button>
            }
            content={renderDisturbanceCodes()}
          />
        )}
      </div>

      <div
        className={`${styles.operationLineTime} flex-start ${selectedDisturbanceCode ? "withDisturbance" : ""}`}
        style={
          selectedDisturbanceCode
            ? {
                border: "0px solid #F4E5CA",
              }
            : {}
        }
      >
        <div className="flex-center">
          <DateAndTimeField
            className={otherStyles.nsgDatetimePicker}
            dateValue={startDate}
            handleChange={handleSetStartDate}
          />
          <button
            type="button"
            className={`${styles.timeLabel} ${styles.timeLabelStart}`}
            title="Start selected"
            onClick={handleStart}
          >
            <span className={textStyles.secondaryText}>START</span>
          </button>
        </div>

        <div className="flex-center">
          <DateAndTimeField
            className={otherStyles.nsgDatetimePicker}
            dateValue={stopDate}
            handleChange={handleSetStopDate}
          />
          <button
            type="button"
            className={`${styles.timeLabel} ${styles.timeLabelStop}`}
            title="Stop selected"
            onClick={handleStop}
          >
            <span className={textStyles.secondaryText}>STOP</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(OperationLineActions);
