import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { Moment } from "moment-timezone";
import React, { FC, memo, useEffect, useMemo, useState } from "react";

import DateAndTimeField from "@/components/common/formElements/dateAndTimeField";
import Input from "@/components/common/formElements/inputText";
import PopUp from "@/components/common/formElements/popUp";
import SentryFallback from "@/components/common/sentryFallback";
import useDeleteTeam from "@/hooks/mutation/useDeleteTeam";
import useUpdateTeamWithOperationLines, {
  TeamWorkOderLineInput,
  UpsertTeamWithOperationLinesModelInput,
} from "@/hooks/mutation/useUpdateTeamWithOperationLines";
import arrow from "@/style/img/arrow.svg";
import dots from "@/style/img/icons/dots.svg";
import {
  Category,
  CenterById,
  Operator,
  Team,
  TeamOperationLine,
  TeamWorkCenter,
} from "@/types";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import { useExistingData } from "./hooks/useExistingData";
import OperationLinesList from "./operationLinesList";
import { GET_CATEGORIES, GET_EQUIPMENT, GET_OPERATORS } from "./queries";
import TeamTagChips from "./teamTagChips";

import st from "../index.module.scss";
import styles from "./index.module.scss";
import buttons from "@/style/buttons.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  setTeams: (newState: Team) => void;
  toggleComponent: () => void;
  teamsCount: number;
  editTeamData: Team | undefined;
  duplicateTeamData: Team | undefined;
  deleteTeam: (id: string) => void;
  setEditData: (editableTeamData: Team | undefined) => void;
  setDuplicateTeamData: (duplicationTeamData: Team | undefined) => void;
};

const CreateNewTeam: FC<Props> = ({
  setTeams,
  toggleComponent,
  teamsCount,
  editTeamData,
  duplicateTeamData,
  deleteTeam,
  setEditData,
  setDuplicateTeamData,
}) => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const userData = useUserData();
  const { setUpdate } = useUpdateTeamWithOperationLines();
  const sendDeleteTeamToBackend = useDeleteTeam();

  const [teamName, setTeamName] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [errors, setErrors] = useState<any>({});
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [newTagValue, setNewTagValue] = useState("");
  const [teamUpsertParams, setTeamUpsertParams] =
    useState<UpsertTeamWithOperationLinesModelInput>({
      model: {
        facilityId: selectedFacility,
        id: "",
        name: "",
        tags: [],
        issuedBy: "",
        teamWorkOrderLines: [],
      },
    });
  const [isActionsOpen, setActionsOpen] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const facilityDataOps = useQuery(GET_OPERATORS, {
    variables: {
      facilityId: selectedFacility,
    },
  });

  const facilityDataCats = useQuery(GET_CATEGORIES, {
    variables: {
      facilityId: selectedFacility,
    },
  });

  const categories = facilityDataCats?.data?.workCentersCategories?.filter(
    (cat: Category) => cat?.planningArea === "GEN WCS",
  );

  const equipmentQuery = useQuery(GET_EQUIPMENT, {
    variables: {
      facilityId: selectedFacility,
    },
  });

  // TODO: Backend bug: We shouldn't have to filter by facilityId, since we ask for specific facilityId
  // Temporal hack

  const allEquipmentRaw = equipmentQuery?.data?.workCentersMachines
    .filter((equip: TeamWorkCenter) => equip?.planningArea === "BASE")
    .filter((equip: TeamWorkCenter) => equip?.facilityId === selectedFacility);

  const allEquipment = useMemo(() => {
    if (!isEmpty(allEquipmentRaw)) {
      return map(
        allEquipmentRaw,
        (equip: {
          workCenterId: string;
          workCenterType: string;
          name: string;
          parentId: string;
          operationElementId: string;
        }) => {
          return {
            value: equip.workCenterId,
            label: equip.name,
            parentId: equip.parentId,
            workCenterType: equip.workCenterType,
            operationElementId: equip.operationElementId,
          };
        },
      );
    }
    return [];
  }, [allEquipmentRaw]);

  const allEquipmentById = useMemo(() => {
    if (!allEquipment || allEquipment.length === 0) {
      return {};
    }
    const container: CenterById = {};
    allEquipment.forEach((item) => {
      container[item.value] = {
        value: item.value,
        label: item.label,
        parentId: item.parentId,
        workCenterType: item.workCenterType,
        operationElementId: item.operationElementId,
      };
    });

    return container;
  }, [allEquipment]);

  const allCategories = useMemo(() => {
    if (!isEmpty(categories)) {
      return map(
        categories,
        (e: { workCenterId: string; workCenterType: string; name: string }) => {
          return {
            value: e.workCenterId,
            label: e.name,
            workCenterType: e.workCenterType,
            operationElementId: e.operationElementId,
          };
        },
      );
    }
    return [];
  }, [categories]);

  const allCategoriesById = useMemo(() => {
    if (!allCategories || allCategories.length === 0) {
      return {};
    }
    const container: any = {};

    allCategories.forEach((item) => {
      container[item.value] = {
        value: item.value,
        label: item.label,
        workCenterType: item.workCenterType,
        operationElementId: item.operationElementId,
      };
    });

    return container;
  }, [allCategories]);

  // TODO: Backend bug: We shouldn't have to filter by facilityId, since we ask for specific facilityId
  const allPlanningAreas =
    facilityDataOps?.data?.operators?.filter(
      (cat: Operator) => cat?.facilityId === selectedFacility,
    ) ?? [];

  const operators = facilityDataOps?.data?.operators
    ?.filter((cat: Operator) => cat?.planningAreaId === "BASE")
    // TODO: Backend bug: We shouldn't have to filter by facilityId, since we ask for specific facilityId
    .filter((cat: Operator) => cat?.facilityId === selectedFacility);

  const allOperators = useMemo(() => {
    if (!isEmpty(operators)) {
      return map(
        operators,
        (e: {
          id: string;
          firstName: string;
          lastName: string;
          planningAreaId: string;
        }) => {
          return {
            value: e.id,
            label: `${e.firstName} ${e.lastName}`,
            planningAreaId: e.planningAreaId,
          };
        },
      );
    }
    return [];
  }, [operators]);

  const allOperatorsById = useMemo(() => {
    if (!allOperators || allOperators.length === 0) {
      return {};
    }
    const container: any = {};

    allOperators.forEach((item) => {
      container[item.value] = {
        value: item.value,
        label: item.label,
        planningAreaId: item.planningAreaId,
      };
    });

    return container;
  }, [allOperators]);

  const handleSetDate = (date: Moment | null) => {
    setStartDate(date);
  };

  const handleSaveTeam = () => {
    // TODO. This type conversion could be improved.
    const operationLines = teamUpsertParams.model.teamWorkOrderLines as unknown;
    const newTeam = {
      name: teamName,
      tags,
      id: (teamsCount + 1).toString(),
      operationLines: operationLines as TeamOperationLine[],
      // NEW
      baseOperatorId: null,
      startDate: "",
      isSynchronized: false,
      lastModifiedBy: "",
      lastModifiedDate: "",
      __typename: "_",
    } as Team;

    teamUpsertParams.model.facilityId = selectedFacility;
    teamUpsertParams.model.tags = tags;
    teamUpsertParams.model.id = (teamsCount + 1).toString();

    if (editTeamData) {
      const editedTeam: Team = {
        baseOperatorId: editTeamData.baseOperatorId,
        id: editTeamData.id,
        startDate: editTeamData.startDate,
        isSynchronized: editTeamData.isSynchronized,
        lastModifiedBy: editTeamData.lastModifiedBy,
        lastModifiedDate: editTeamData.lastModifiedDate,
        name: editTeamData.name,
        operationLines: editTeamData.operationLines,
        tags: editTeamData.tags,
        // eslint-disable-next-line no-underscore-dangle
        __typename: editTeamData.__typename,
      };

      setTeams(editedTeam);
      setUpdate(
        teamName,
        tags,
        userData.mail,
        selectedFacility,
        teamUpsertParams.model.teamWorkOrderLines,
        startDate?.toDate() || null,
        editTeamData.id,
      );
    } else {
      setTeams(newTeam);
      setUpdate(
        teamName,
        tags,
        userData.mail,
        selectedFacility,
        teamUpsertParams.model.teamWorkOrderLines,
        startDate?.toDate() || null,
      );
    }
    setDuplicateTeamData(undefined);
    toggleComponent();
  };

  const handleOperationItemsUpdateAfterItemAddition = (
    operationLinesAfterAddition: TeamWorkOderLineInput[],
  ) => {
    const newTeamUpsertParam: UpsertTeamWithOperationLinesModelInput = {
      model: {
        facilityId: selectedFacility,
        id: "",
        name: "",
        tags: [],
        issuedBy: "",
        teamWorkOrderLines: [],
      },
    };

    newTeamUpsertParam.model.teamWorkOrderLines = operationLinesAfterAddition;

    // eslint-disable-next-line no-unused-expressions
    operationLinesAfterAddition &&
      setErrors({ ...errors, operationItems: false });

    setTeamUpsertParams(newTeamUpsertParam);
  };

  const handleOperationItemsUpdateAfterItemRemoval = (
    operationLinesAfterRemoval: TeamWorkOderLineInput[],
  ) => {
    const newTeamUpsertParam: UpsertTeamWithOperationLinesModelInput = {
      model: {
        facilityId: selectedFacility,
        id: "",
        name: "",
        tags: [],
        issuedBy: "",
        teamWorkOrderLines: [],
      },
    };

    newTeamUpsertParam.model.teamWorkOrderLines = operationLinesAfterRemoval;

    // eslint-disable-next-line no-unused-expressions
    operationLinesAfterRemoval.length === 0 &&
      setErrors({ ...errors, operationItems: true });

    setTeamUpsertParams(newTeamUpsertParam);
  };

  const handleTeamNameChange = (newName: string) => {
    setTeamName(newName);
    setErrors({ ...errors, name: false });
  };

  const addNewTagToArray = () => {
    if (newTagValue === "") {
      return;
    }
    if (tags.find((e) => e === newTagValue)) {
      setErrors({ ...errors, tags: true, duplicateTag: true });
    } else {
      setTags([...tags, newTagValue]);
      setNewTagValue("");
      if (errors.tags) {
        setErrors({ ...errors, tags: false });
      }
    }
  };

  const removeTag = (tag: string) => {
    setTags(tags.filter((e) => e !== tag));
  };

  const handleTeamBuilderClose = () => {
    // reset the state when the edit/create team option is closed
    setEditData(undefined);
    setDuplicateTeamData(undefined);
    toggleComponent();
  };

  const handleDuplicationFromEditTeam: () => void = () => {
    setDuplicateTeamData(editTeamData);
    setEditData(undefined);
  };

  const handleDeleteFromTeamBuilder = () => {
    if (teamUpsertParams.model.id === "" && editTeamData === undefined) {
      toggleComponent();
    } else if (editTeamData?.id) {
      sendDeleteTeamToBackend(editTeamData.id);
      deleteTeam(editTeamData.id);
      toggleComponent();
    }
  };

  useEffect(() => {
    if (
      teamName !== "" &&
      teamUpsertParams.model.teamWorkOrderLines.length !== 0 &&
      startDate
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [teamName, teamUpsertParams.model.teamWorkOrderLines, startDate]);

  const teamNameErrorHandler = () => {
    if (teamName === "") {
      setErrors({ ...errors, name: true });
    }
  };

  const handleOperationLineBlur = () => {
    if (
      teamUpsertParams.model.teamWorkOrderLines.length === 0 ||
      teamUpsertParams.model.teamWorkOrderLines.some(
        (teamOrderLines) => teamOrderLines.workCenterCategoryId === "",
      )
    ) {
      setErrors({ ...errors, operationItems: true });
    } else {
      setErrors({ ...errors, operationItems: false });
    }
  };

  useExistingData(
    editTeamData,
    duplicateTeamData,
    teamUpsertParams,
    setTeamName,
    setTags,
    setStartDate,
    setTeamUpsertParams,
  );

  return (
    <Sentry.ErrorBoundary fallback={SentryFallback}>
      <div className={st.teamBuilderHeader}>
        <button type="button" onClick={handleTeamBuilderClose}>
          <img src={arrow} alt="arrow" />
          {`TEAM BUILDER / ${editTeamData ? "EDIT" : "CREATE NEW"} TEAM`}
        </button>
        <div className={styles.secondaryActionButton}>
          <button
            className={buttons.modalButton}
            type="button"
            onClick={handleSaveTeam}
            disabled={isSaveDisabled}
            style={{ cursor: isSaveDisabled ? "not-allowed" : "pointer" }}
            title={
              isSaveDisabled
                ? "Complete all the fields marked with * to save"
                : ""
            }
          >
            Save team
          </button>
          <PopUp
            trigger={
              <section className={styles.popUp}>
                <img src={dots} alt="settings" />
              </section>
            }
            onClose={() => setActionsOpen(false)}
            onOpen={() => setActionsOpen(true)}
            isOpen={isActionsOpen}
            content={
              <div className={`${styles.actionsPopUp} popUp-wrapper`}>
                {editTeamData && (
                  <button
                    type="button"
                    className={styles.optionButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDuplicationFromEditTeam();
                      setActionsOpen(false);
                    }}
                  >
                    DUPLICATE
                  </button>
                )}
                <button
                  type="button"
                  className={styles.optionButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteFromTeamBuilder();
                  }}
                >
                  DELETE
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className={styles.createNewTeam}>
        <div>
          <div>
            <span className={textStyles.secondaryText}>
              <span className="error-message">*</span> Name:
            </span>
            <Input
              value={teamName}
              setValue={(e) => handleTeamNameChange(e)}
              placeholder="Team Name"
              error={!!errors.name}
              onBlur={teamNameErrorHandler}
              errorMessage="We need your input here."
            />
          </div>
          <div>
            <span className={textStyles.secondaryText}>
              <span className="error-message">*</span> Planned For:
            </span>
            <div style={{ height: "51px" }}>
              <DateAndTimeField
                dateValue={startDate}
                customStyle={{
                  input: { height: "38px" },
                }}
                handleChange={(date) => {
                  handleSetDate(date);
                  if (!date) {
                    setErrors({ ...errors, dateTime: true });
                  } else {
                    setErrors({ ...errors, dateTime: false });
                  }
                }}
                errorJSX={
                  errors?.dateTime ? (
                    <p className="error-message">We need your input here.</p>
                  ) : undefined
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <span className={textStyles.secondaryText}> Tags:</span>
            <Input
              value={newTagValue}
              setValue={(e) => setNewTagValue(e)}
              placeholder="Tag Descriptor"
              error={!!errors.tags}
              errorMessage={
                errors.duplicateTag
                  ? "This tag already exists. Try another one."
                  : "We need your input here."
              }
              onBlur={addNewTagToArray}
              onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === "Enter") {
                  addNewTagToArray();
                }
              }}
            />
          </div>
        </div>
        <TeamTagChips tags={tags} handleRemoveTag={removeTag} />
        <section className={styles.operationLines}>
          <span className={textStyles.secondaryText}>
            <span className="error-message">*</span> OPERATION LINES:
          </span>
          <div className={styles.operationLinesHeader}>
            <div className={textStyles.secondaryText}>CATEGORY</div>
            <div className={textStyles.secondaryText}>MACHINE</div>
            <div className={textStyles.secondaryText}>EMPLOYEE</div>
          </div>
          <OperationLinesList
            allCategories={allCategories}
            allCategoriesById={allCategoriesById}
            allEquipment={allEquipment}
            allEquipmentById={allEquipmentById}
            allOperators={allOperators}
            allOperatorsById={allOperatorsById}
            allOperatorsPlanningAreas={allPlanningAreas}
            error={!!errors.operationItems}
            onBlur={handleOperationLineBlur}
            onOperationItemsAddition={(updatedLines) => {
              // setTimeout to avoid error: Cannot update a component (`CreateNewTeam`) while rendering a different component (`OperationLinesWrapper`)
              // There is no reason for this error, these actions are called inside event handlers, never on render.
              setTimeout(() => {
                handleOperationItemsUpdateAfterItemAddition(updatedLines);
              }, 0);
            }}
            onOperationItemsRemoval={(updatedLines) => {
              setTimeout(() => {
                handleOperationItemsUpdateAfterItemRemoval(updatedLines);
              }, 0);
            }}
            existingOperationItems={
              editTeamData?.operationLines || duplicateTeamData?.operationLines
            }
          />
        </section>
      </div>
    </Sentry.ErrorBoundary>
  );
};
export default memo(CreateNewTeam);
