import confetti from "canvas-confetti";
import React, { FC, useEffect, useMemo } from "react";
import CurrencyFormat from "react-currency-format";

import done from "@/style/img/done_gold.svg";

import Step2Row, { InvoiceLine } from "./InvoiceTableStep2Row";

import styles from "./index.module.scss";

type Props = {
  invoiceLines: any[];
  recentlyApproved: boolean;
};

// Function to play the sound
const playSound = () => {
  // Define the URL to the sound file using the current website's base URL
  const soundPath = `${window.location.origin}/ka-ching.mp3`;

  // Create an audio instance
  const audio = new Audio(soundPath);
  audio.play();
};

function fire(particleRatio, opts) {
  const count = 200;
  const defaults = {
    origin: { y: 0.7, x: 0.62 },
  };
  confetti({
    ...defaults,
    ...opts,
    particleCount: Math.floor(count * particleRatio),
  });
}
function success() {
  playSound();

  setTimeout(() => {
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 70,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });

    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  }, 700);
}
const InvoiceTableStep3: FC<Props> = ({
  invoiceLines,
  recentlyApproved = false,
}) => {
  const sortedInvoiceLines = useMemo(() => {
    // Check if orderLines is iterable
    if (!Array.isArray(invoiceLines)) {
      // Return an empty array or handle the case as appropriate
      return [];
    }
    // Sort the operation lines using the custom comparator
    // Create a new array from orderLines because sometimes could complain about "read-only" array
    const sortedLines = [...invoiceLines].sort((a, b) => {
      if (a.itemName < b.itemName) {
        return -1;
      }
      if (a.itemName > b.itemName) {
        return 1;
      }
      return 0;
    });
    console.debug("step 2 lines", sortedLines);
    return sortedLines;
  }, [invoiceLines]);

  useEffect(() => {
    if (recentlyApproved) {
      success();
    }
  }, [recentlyApproved]); // Dependency array ensures the effect runs when wo.status changes

  return (
    <div className={styles.tableStep1}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={`${styles.successArea} ${styles.recentlyApproved}`}
        onClick={success}
      >
        <img
          src={done}
          alt="Process finished"
          style={{
            margin: "2px 15px -4px 3px",
            width: "30px",
            height: "30px",
            verticalAlign: "bottom",
          }}
        />
        The invoice has been approved!
      </div>
      <div className={styles.section}>
        <div className={`${styles.gridRow2} ${styles.headers}`}>
          <div style={{ marginLeft: "15px" }}>Operation</div>
          <div>Description</div>
          <div>Transaction date</div>
          <div>Quantity</div>
          <div>Price per unit</div>
          <div>Sales price</div>
          <div>Discount percentage</div>
          <div>Total sales price</div>
        </div>
      </div>
      {sortedInvoiceLines.map((line) => {
        return (
          <Step2Row readOnly key={line.id} invoiceLine={line as InvoiceLine} />
        );
      })}
      <div className={`${styles.totalSection}`}>
        <CurrencyFormat
          value={sortedInvoiceLines
            .reduce((accumulator, line) => {
              return accumulator + line.totalSalesPrice;
            }, 0)
            .toFixed(2)}
          displayType="text"
          thousandSeparator
          prefix="NOK "
          renderText={(value) => (
            <div style={{ paddingTop: 12, color: "black" }}>{value}</div>
          )}
        />
      </div>
      <br />
    </div>
  );
};

export default InvoiceTableStep3;
