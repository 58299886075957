import { gql, useMutation } from "@apollo/client";
import moment, { Moment } from "moment-timezone";
import React, { FC, memo, useState } from "react";

import DateAndTimeField from "@/components/common/formElements/dateAndTimeField";
import ArrowSVG from "@/components/common/formElements/multiselect/arrow";
import PopUp from "@/components/common/formElements/popUp";
import { useUserData } from "@/zustand/useSettingsStore";

import styles from "./index.module.scss";
import otherStyles from "@/components/timeline/sidePanel/sidePanel.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  dates: {
    startDate: string;
    stopDate: string | null;
  };
  disturbance:
    | {
        id: string;
        name: string;
        description: string;
      }
    | undefined;
  timestampId: string | undefined;
  operatorId: string | undefined;
};

const UPDATE_START_TIME = gql`
  mutation updateTimestampStartTime(
    $employeeNumber: String!
    $issuedBy: String!
    $startDate: DateTime!
    $timestampId: String!
  ) {
    updateTimestampStartTime(
      employeeNumber: $employeeNumber
      issuedBy: $issuedBy
      startDate: $startDate
      timestampId: $timestampId
    )
  }
`;

const UPDATE_STOP_TIME = gql`
  mutation updateTimestampStopTime(
    $employeeNumber: String!
    $issuedBy: String!
    $stopDate: DateTime!
    $timestampId: String!
  ) {
    updateTimestampStopTime(
      employeeNumber: $employeeNumber
      issuedBy: $issuedBy
      stopDate: $stopDate
      timestampId: $timestampId
    )
  }
`;

const UPDATE_DISTURBANCE = gql`
  mutation updateDisturbanceCode(
    $disturbanceId: String!
    $issuedBy: String!
    $timestampId: String!
  ) {
    updateDisturbanceCode(
      disturbanceId: $disturbanceId
      issuedBy: $issuedBy
      timestampId: $timestampId
    )
  }
`;

const OperationLineDates: FC<Props> = ({
  dates,
  timestampId,
  disturbance,
  operatorId,
}) => {
  const userData = useUserData();
  const [updateTimestampStartTime] = useMutation(UPDATE_START_TIME);
  const [updateTimestampStopTime] = useMutation(UPDATE_STOP_TIME);
  const [updateDisturbanceCode] = useMutation(UPDATE_DISTURBANCE);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const handleUpdateStartDate = (newDate: Moment | null) => {
    if (!timestampId || !operatorId || !newDate) return;
    updateTimestampStartTime({
      variables: {
        employeeNumber: operatorId,
        issuedBy: userData.mail,
        startDate: newDate?.toISOString() || "",
        timestampId,
      },
    });
  };

  const handleUpdateStopDate = (newDate: Moment | null) => {
    if (!timestampId || !operatorId || !newDate) return;
    updateTimestampStopTime({
      variables: {
        employeeNumber: operatorId,
        issuedBy: userData.mail,
        stopDate: newDate?.toISOString() || "",
        timestampId,
      },
    });
  };
  const handleUpdateDisturbance = async (disturbanceId: string) => {
    if (!timestampId || !disturbanceId) return;
    setIsLoadingUpdate(true);
    try {
      await updateDisturbanceCode({
        variables: {
          disturbanceId,
          issuedBy: userData.mail,
          timestampId,
        },
      });
    } catch (error) {
      console.error("Error updating disturbance code:", error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const renderDisturbanceCodes = () => {
    return (
      <div className={styles.workCenterList}>
        {window?.disturbanceCodes?.map((disturbance: any) => (
          <div
            tabIndex={0}
            role="button"
            onKeyDown={() => null}
            onClick={() => {
              handleUpdateDisturbance(disturbance?.id);
              setIsPopUpOpen(false);
            }}
            key={disturbance.name}
          >
            <span
              title={disturbance.description}
              className={textStyles.primaryText}
            >
              {disturbance?.description}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={`${styles.datePickers} ${disturbance?.id ? styles.withDisturbance : ""}`}
    >
      {disturbance ? (
        <PopUp
          position="right top"
          isOpen={isPopUpOpen}
          onOpen={() => setIsPopUpOpen(true)}
          onClose={() => setIsPopUpOpen(false)}
          trigger={
            <button
              className={styles.disturbanceButton}
              type="button"
              onClick={() => setIsPopUpOpen(true)}
            >
              {isLoadingUpdate
                ? `${disturbance.description} ...`
                : disturbance.description}
              <ArrowSVG />
            </button>
          }
          content={renderDisturbanceCodes()}
        />
      ) : null}
      <DateAndTimeField
        disableOpenPicker
        className={otherStyles.nsgDatetimePicker}
        dateValue={dates.startDate ? moment(dates.startDate) : undefined}
        handleChange={handleUpdateStartDate}
      />
      {dates.stopDate && (
        <DateAndTimeField
          disableOpenPicker
          className={otherStyles.nsgDatetimePicker}
          dateValue={dates.stopDate ? moment(dates.stopDate) : undefined}
          handleChange={handleUpdateStopDate}
        />
      )}
    </div>
  );
};
export default memo(OperationLineDates);
