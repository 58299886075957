/* eslint-disable @typescript-eslint/no-explicit-any */

export type SelectSingleProps = {
  value: string;
  label: string;
};

export interface SelectWithcolor extends SelectSingleProps {
  color: string;
}

export type User = {
  displayName: string;
  mail: string;
};

export type Quay = {
  facilityId?: string;
  id: string;
  name: string;
  __typename: string;
};

export type GhostWorkOrderData = {
  customerName: string;
  name: string;
  plannedStart: string;
  plannedStop: string;
  reserve: string;
  ghostWorkOrderId?: string;
};

export type Facility = {
  id: string;
  name: string;
  __typename: string;
};

export type VisualColorTheme = {
  color: string;
  background: string;
};

export type OrderStatusColor = VisualColorTheme & {
  name: string;
};

type WorkOrdertype = {
  description: string | null;
  id: string;
  name: string;
  status: string;
  __typename: string;
};

type MCO = {
  referenceOrderLine: string;
  referenceOrderNumber: string;
};

export type Note = {
  description: string;
  entryDate: string;
  id: string;
  lastModified: string;
  noteType: string;
  text: string;
  textIdentity: string;
  modifiedBy: string;
  specifyDescription: string;
};

export type Attachment = {
  id: string;
  attachmentType: string;
  displayName: string;
  filename: string;
  size: number;
  uploadDate: string;
  modifiedDate: string;
  // Local values:
  uploading: boolean;
  deleting: boolean;
};

type LoadingStatusQtys = {
  canNotProcessQty: number;
  quaySideQty: number;
  roundTripQty: number;
  vesselQty: number;
  warehouseQty;
};

type KoncivLoadingStatus = {
  planId: number;
  planType: string;
  pipeLoad: LoadingStatusQtys;
  regularLoad: LoadingStatusQtys;
};

export type WorkOrder = {
  orderLines: any;
  assignedTeam: AssignedTeam;
  customerName: string;
  customerDisplayName: string;
  description: string;
  facilityId: string;
  orderTypeId: string;
  poNumber: string | null;
  orderedBy: string | null;
  id: string;
  name: string;
  orderBy: string;
  orderDate: string;
  orderNumber: string;
  plannedStartDate: string;
  plannedStopDate: string;
  startDate: string;
  status: string;
  derivedStatus: string;
  stopDate: string;
  vesselRelated: boolean;
  mCO: MCO;
  loadingStatusSummary: KoncivLoadingStatus;
  normalizedTotalPipeLoad: number;
  normalizedTotalRegularLoad: number;
  hideInTimeline?: boolean;
  workOrderType: WorkOrdertype;
  workOrderNotes: Note[];
  operationLines: any[];
  attachments: Attachment[];
  normalizedQuay: string;
  allocHeight: number;
  visualTop: number;
  __typename: string;
};

type VesselInfo = {
  id: string;
  name: string;
  imoNumber: string;
  isSynchronized: boolean;
};
export type VesselPreviewInfo = {
  id: string;
  rentalAgreementNumber: string;
  rentalAgreementLine: string;
  customerName: string | null;
  quay: string | null;
  plannedStartDate: string;
  plannedStopDate: string;
  status: string;
  vessel: VesselInfo;
  workOrders: {
    id: string;
    name: string;
    status: string;
    orderDate: string;
    description: string;
  }[];
  normalizedQuay: string;
};

export type GhostActivity = {
  customerName: string;
  id: string;
  lastModified: string;
  name: string;
  plannedStartDate: string;
  plannedStopDate: string;
  quay: string;
  attachments: Attachment[];
  __typename: string;
  normalizedQuay: string;
};

export type Vessel = {
  groupTop: number;
  groupHeight: number;
  id: string;
  poNumber: string | null;
  shorePowerUsage: any;
  vesselName: string;
  orderedBy: string | null;
  rentalAgreementNumber: string;
  rentalAgreementLine: string;
  vesselImo: string;
  customerName: string | null;
  quay: string | null;
  startDate: string;
  stopDate: string;
  plannedStartDate: string;
  plannedStopDate: string;
  isSynchronized: string | null;
  __typename: string;
  status: string;
  derivedStatus: string;
  vessel: VesselInfo;
  workOrders: WorkOrder[];
  attachments: Attachment[];
  normalizedQuay: string;
  allocHeight: number;
  allocationCollapsed: boolean;
  visualTop: number;
  stackIndex: number;
};

export type WorkOrderLineTypes = {
  capability: string;
  description: string;
  id: string;
  operationNumber: string;
  plannedStartDate: string;
  plannedStopDate: string;
  startDate: string;
  status: string;
  derivedStatus: string;
  stopDate: string;
  workOrderId: string;
  timestampOperations: {
    id: string;
    startDate: string;
    stopDate: string;
    status: string;
    disturbance: {
      id: string;
      name: string;
      description: string;
    };
  }[];
  assignment: Assignment;
  __typename: string;
};

type CapabilityTypes = "NON_WORK_CENTER" | "WORK_CENTER";

export type Assignment = {
  id: string;
  operationElementId: string;
  capabilityType: CapabilityTypes; // Do an ENUM thing
  capabilityTypeNumber: number;
  description: string;
  operator: Operator | null;
  workCenter: {
    id: string;
    name: string;
    description: string;
    workCenterId: string;
  } | null;
  workOrderLineNumber: string;
  workOrderType: string;
  workCenterType: string;
};

export type Category = {
  id: string;
  name: string;
  workCenterId: string;
  planningArea: string;
};

export type WorkOrderLineWithTimestampOperations = {
  workOrderId: string;
  capability: string;
  id: string;
  description: string;
  operationNumber: string;
  plannedStartDate: string;
  plannedStopDate: string;
  assignment: Assignment;
  workCenter: {
    id: string;
    name: string;
    description: string;
    parentId: string;
  } | null;
  __typename: string;
};

export type WorkOrderLineOperator = {
  facilityId: string;
  firstName: string;
  id: string;
  lastName: string;
  middleName: string;
  // isAssigned: boolean;
  planningAreaId: string;
  planningAreaCount?: string;
  __typename: string;
};

export type WorkOrderLineEquipment = {
  // isAssigned: boolean;
  description: string;
  id: string;
  name: string;
  workCenterId: string;
  planningArea: string;
  __typename: string;
};

/**
 * Utility types start
 */
export type FunctionParams<
  F extends (...args: any) => any,
  I extends number = 0,
> = Parameters<F>[I];

type Nil = null | undefined;

export type Nullable<T> = T | Nil;

/**
 * Utility types end
 */

type CenterWithParent = {
  value: string;
  label: string;
  parentId: string;
  workCenterType: string;
  operationElementId: string;
};

type CategoryData = {
  value: string;
  label: string;

  workCenterType: string;
};
export type CenterById = {
  [key: string]: CenterWithParent;
};
export type ItemById = {
  [key: string]: CategoryData;
};

export type TeamWorkCenter = {
  name: string;
  workCenterType: string;
  planningArea: string;
  facilityId: string;
};

export type Operator = {
  id: string;
  firstName: string;
  cardNumber: string;
  middleName?: string;
  lastName: string;
  planningAreaId: string;
  facilityId: string;
  __typename: string;
};

export type TeamOperationLine = {
  workCenterMachine: TeamWorkCenter | undefined;
  workCenterMachineId: string;
  id: string;
  issuedBy: string;
  // TODO change operator data structure
  operator: Operator;
  operatorId: string;
  workCenterCategoryId: string;
  workCenterCategory: TeamWorkCenter | undefined;
  __typename: string;
};

export interface Team {
  baseOperatorId: string | null;
  id: string;
  startDate: string;
  isSynchronized: boolean;
  lastModifiedBy: string;
  lastModifiedDate: string;
  name: string;
  operationLines: TeamOperationLine[];
  tags: string[];
  __typename: string;
}

type AssignedTeam = Pick<Team, "id" | "name">;

export enum DraggableItemType {
  Order = "order",
  GhostOrder = "ghost-order",
  WorkOrder = "work-order",
  SubWorkOrder = "sub-work-order",
  DraggableSide = "draggable-side",
}

export type DroppableItemType = Exclude<DraggableItemType, "draggable-side">;

type DragScopeParams = string[];

type DragScopeParamsHost = {
  dragScope?: DragScopeParams;
  excludeDragScopes?: DragScopeParams;
};

export type DraggableDnDItem = Partial<DragScopeParamsHost> & {
  orderId: string;
  parentVesselId?: string;
  quayId: string;
  orderLineNumber?: string;
  orderNumber?: string;
};

type DraggableSidePosition = "start" | "end";

export type DraggableSideDnDItem = DraggableDnDItem & {
  position: DraggableSidePosition;
  parentItemType: DroppableItemType;
  element: Nullable<HTMLElement>;
};

export type DraggingToggleHost = {
  canDrag: boolean;
};

export type DraggableItemProps = Omit<DraggableDnDItem, "orderId"> &
  DraggingToggleHost &
  DragScopeParamsHost;

export type DraggableSideProps = DraggableDnDItem &
  Pick<DraggableSideDnDItem, "position" | "parentItemType"> & {
    styles: {
      position: Partial<{
        top: number;
        left: number;
        right: number;
        bottom: number;
      }>;
      backgroundColor: string | undefined;
    };
    onDrop: OnDraggableSideDropFn;
  };

type DraggableSideDragState = {
  item: Nullable<DraggableSideDnDItem>;
  itemType: Nullable<DraggableItemType>;
  parentItemType: Nullable<DroppableItemType>;
  differenceByXFromInitialOffset: Nullable<number>;
  clientOffset: Nullable<{ x: number; y: number }>;
};

export type OnDraggableSideDropFn = (state: DraggableSideDragState) => void;

export type OnDropPayload = {
  orderId: string;
  toTime: string;
  toQuay: string;
  type: DroppableItemType;
};

export type DropEventEmitter = {
  onDrop: (result: OnDropPayload) => void;
};

export type OrderUpdateEventOrderType =
  | "vessel"
  | "work-order"
  | "sub-work-order"
  | "ghost-activity";

type OrderUpdatedEventCommonParams = Pick<
  Vessel,
  "plannedStartDate" | "plannedStopDate"
> & {
  id: string;
  toTime: string;
  orderType: OrderUpdateEventOrderType;
};

export type OrderUpdatedEventParams = OrderUpdatedEventCommonParams & {
  poNumber?: string; // TODO: Make it non optional once this feature is released
  orderedBy: string;
  quay: string;
};

export type GhostActivityUpdatedEventParams = OrderUpdatedEventCommonParams & {
  toQuay: string;
} & Pick<GhostActivity, "customerName" | "name">;

export type OnOrderUpdatedFn<
  D extends OrderUpdateEventOrderType = OrderUpdateEventOrderType,
> = <T extends D = D>(
  order: T extends "ghost-activity"
    ? GhostActivityUpdatedEventParams
    : OrderUpdatedEventParams,
) => void;
