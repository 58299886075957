import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { SentryLink } from "apollo-link-sentry";
import { Kind } from "graphql/language/kinds";

import { setApolloClient } from "@/zustand/useOrderStore";

// Intercept all mutations so we can avoid Nordlys/Dolittle employees to modify data in production by mistake
const mutationInterceptorLink = new ApolloLink((operation, forward) => {
  const isMutation = operation.query.definitions.some((definition) => {
    return (
      definition.kind === Kind.OPERATION_DEFINITION &&
      definition.operation === "mutation"
    );
  });

  // Extra safe guards here: if by mistake we put in localhost the Prod Gateway URL
  const isProdGateway =
    window?.PB_GATEWAY_URL?.includes("pb.norseagroup.com/gateway") ||
    window?.DOLITTLE_ENVIRONMENT === "prod";

  const bypassMutationProtection = window?.BYPASS_MUTATION_PROTECTION ?? false;

  const NotAllowedList = [
    "alexandar.sunjic@norseagroup.com",
    "jovana.pavic@norseagroup.com",
    "david.corbachoroman@norseagroup.com",
    "karol.grzeslak@norseagroup.com",
    "bjoern.heber@norseagroup.com",
  ];

  if (isMutation && isProdGateway && !bypassMutationProtection) {
    // Inspect the query and user information to determine if the mutation should be allowed
    const userEmail = (window?.CURRENT_USER_EMAIL || "").toLowerCase();
    const userIsNotAllowed =
      userEmail &&
      (userEmail?.endsWith("@dolittle.com") ||
        userEmail?.endsWith("@nordlys.studio") ||
        NotAllowedList.includes(userEmail));

    if (userIsNotAllowed) {
      console.error(
        `Current user is not allowed to perform mutations in production: '${userEmail}'. Bypass the protection with window.BYPASS_MUTATION_PROTECTION = true;`,
      );
      return null; // Block the mutation by not forwarding the operation
    }
  }
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // log any GraphQL errors to the console
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      );
    });
  }

  // log any network errors to the console
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

let apolloClient;
export function createApolloClient(url, tokenValidityCheck) {
  if (!apolloClient) {
    const withValidToken = setContext(async (_, { headers }) => {
      // Here we need to access the token via window.BEARER because using "activeToken" directly
      // won't load the latest token after been refreshed. (Because closures? or how ApolloLink works)
      await tokenValidityCheck();
      return {
        headers: {
          ...headers,
          Authorization: window.BEARER ? `Bearer ${window.BEARER}` : null,
        },
      };
      // we could return null; if we want to abort the apollo link middleware chain
    });

    const httpLink = createHttpLink({
      uri: url,
    });
    const wsLink = new WebSocketLink({
      uri: url.replace(/http/, "ws"),
      options: {
        reconnect: true,
        lazy: true,
        connectionParams: async () => ({
          authToken: await tokenValidityCheck(),
        }),
      },
    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink,
    );

    apolloClient = new ApolloClient({
      link: ApolloLink.from([
        withValidToken,
        mutationInterceptorLink,
        errorLink,
        new SentryLink({
          attachBreadcrumbs: {
            includeQuery: true,
            includeVariables: false,
            includeError: true,
          },
        }),
        splitLink,
      ]),
      connectToDevTools: true, // Connects to Apollo Devtools chrome extension (not enabled for "prod" mode)
      cache: new InMemoryCache({
        typePolicies: {
          CapabilityRequirement: {
            keyFields: false, // Disable normalization
          },
          Query: {
            fields: {
              facilityById: {
                // shorthand to merge all the fields (some queries can return some fields, some other queries, other fields). There is no conflict
                merge: true,
              },
              vesselActivity: {
                merge: true,
              },
              VesselWorkOrders: {
                merge: true,
              },
              WorkOrderWithLines: {
                merge: true,
              },
              workOrderById: {
                merge: true,
              },
              capableOperators: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
        },
      }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
          errorPolicy: "ignore",
        },
        query: {
          fetchPolicy: "network-only",
          errorPolicy: "all",
        },
        mutate: {
          errorPolicy: "all",
        },
      },
    });
    setApolloClient(apolloClient);
  }
  return apolloClient;
}
