/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from "@mui/material";
import omit from "lodash/omit";
import React, {
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import DialogModal from "@/components/common/dialogModal";
import { LOADING_STYLE } from "@/helpers/constants";
import {
  OrderStatusColors,
  OrderStatusEnum,
} from "@/helpers/OrderStatusColors";
import { useDraggableItem } from "@/hooks/ui/timeline/useDraggableItem";
import { useNeedToMakeItemSecondary } from "@/hooks/ui/timeline/useNeedToMakeItemSecondary";
import { useOrderSize } from "@/hooks/ui/timeline/useOrderSize";
import {
  DraggableItemProps,
  DraggableItemType,
  OnDraggableSideDropFn,
  Vessel,
  VisualColorTheme,
  WorkOrder as WorkOrderTypes,
} from "@/types";
import { useInProgressStore } from "@/zustand/useInProgressStore";
import { setSidePanelOrder, useOrderStore } from "@/zustand/useOrderStore";
import { useSettingsStore } from "@/zustand/useSettingsStore";
import { SuperLinkTeam } from "@/zustand/zustand-types";

import OrderCardDraggableSides from "../draggableSide/orderCardDraggableSides";
import { isInsideModal } from "../helpers/isInsideModal";
import AssignTeamModal from "./modalsContent/assignTeamModal";
import DeleteWorkOrderModal from "./modalsContent/deleteWorkOrderModal";
import VesselSubWorkOrderLoad from "./vesselSubWorkOrderLoad";
import WorkOrderActions from "./workOrderActionsPopUp";

import styles from "../index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type DragWorkOrderProps = {
  id: string;
  workOrder: WorkOrderTypes;
  parentVessel: Vessel;
  elementWidth: number;
  position: number;
  selectedDate: Date;
  visibleDays: number;
  timeLine: string[];
  onPullEnd: OnDraggableSideDropFn;
};

const draggableItemType = DraggableItemType.SubWorkOrder;

const VesselSubWorkOrderAllocation = forwardRef<
  HTMLInputElement,
  DragWorkOrderProps
>(
  (
    {
      workOrder,
      parentVessel,
      elementWidth,
      position,
      selectedDate,
      visibleDays,
      timeLine,
      onPullEnd,
    },
    ref,
  ) => {
    const sidePanelOrder = useOrderStore((state) => state.sidePanelOrder);
    const woChangeInProgress = useInProgressStore(
      (state) => state.woChangeInProgress,
    );

    const inDetailedView = useSettingsStore((state) => state.inDetailedView);
    const [isModalDeleteVessel, setIsModalDeleteVessel] = useState(false);
    const [startAssignment, setStartAssignment] = useState(false);
    const { visibleStartDate } = useSettingsStore(
      (state) => state.visibleDateRange,
    );
    const superLinking = useSettingsStore((state) => state.superLinking);
    const disableSuperLinkTooltip = useSettingsStore(
      (state) => state.disableSuperLinkTooltip,
    );

    const orderInfo = useRef<any>(null);
    const isLoading = woChangeInProgress.some(
      (each) => each.id === workOrder.id,
    );
    // TODO: Evaluate if it has sense to track this "inprogress" too (then we need to set it up)
    // const isLoading2 = woLineChangeInProgress.some((inProgress) => {
    //   return workOrder?.orderLines?.some(
    //     (eachLine) => eachLine.id === inProgress.id
    //   );
    // });
    // const isLoading = isLoading1 || isLoading2;

    const draggableProps: DraggableItemProps = {
      canDrag: !isLoading,
      quayId: workOrder.normalizedQuay,
      parentVesselId: parentVessel.id,
      orderLineNumber: workOrder?.mCO?.referenceOrderLine,
      orderNumber: workOrder?.mCO?.referenceOrderNumber,
      // There is no reason to limit the dragScope to certain Quay, because vessel clones can be in another quay. And the mutations will not take into consideration the quay at all
      // dragScope: [workOrder.normalizedQuay],
    };
    const [{ isDragging }, drag] = useDraggableItem({
      type: draggableItemType,
      timeLine,
      orderId: workOrder.id,
      ...draggableProps,
    });

    const getVisualColorTheme = useCallback(
      (status: string): VisualColorTheme => {
        return isLoading
          ? LOADING_STYLE
          : OrderStatusColors.getStatus(status as OrderStatusEnum);
      },
      [isLoading],
    );

    const needToMakeItemSecondary = useNeedToMakeItemSecondary({
      order: workOrder,
    });

    const renderEventInfo = (order: WorkOrderTypes) => {
      const textStyle = {
        overflow: "hidden",
        fontWeight: 700,
        color: isLoading ? "#C4C4C4" : "inherit",
      };
      const subtitleStyle = {
        overflow: "hidden",
        color: isLoading ? "#C4C4C4" : "inherit",
        marginTop: "8px",
      };

      const JSX = (
        <div
          key={order.id}
          className={styles.workOrderBlock}
          style={{ display: "block" }}
        >
          <div className={styles.eventInfo}>
            <span style={textStyle} className={textStyles.primaryText}>
              {`${order.description} - ${order.customerDisplayName}`}
            </span>
          </div>

          <div className={styles.eventInfoBlock}>
            <div
              key={order.id}
              className={styles.workOrderBlock}
              style={{ display: "flex" }}
            >
              <div className={styles.eventInfo}>
                <span
                  style={subtitleStyle}
                  className={textStyles.secondaryText}
                >
                  {`#${order.orderNumber.substring(
                    order.orderNumber.length - 5,
                  )}`}

                  {/* <span
                    style={subtitleStyle}
                    className={textStyles.secondaryText}
                  >
                    {workOrder?.orderLines && ` - ${teamInfo}`}
                    {!workOrder?.orderLines && "..."}
                  </span> */}
                </span>
              </div>
            </div>
            <VesselSubWorkOrderLoad subWo={workOrder} />
          </div>
        </div>
      );
      return JSX;
    };

    const { width } = useOrderSize({
      order: workOrder,
      selectedDate,
      elementWidth,
      visibleDays,
    });

    // @TODO: check can the implementation from the vessels card be reused
    const orderStyles = useMemo(() => {
      let finalWidth = Math.max(width, elementWidth * 3); // elementWidth*3, why 3 (3 hours)? Because it's min width of 3 hours we declared in intervalGraphColoring.
      // Calculations in minutes
      const finalStartDate = workOrder?.plannedStartDate;
      const startDiff = -visibleStartDate.diff(finalStartDate, "minutes");
      const shift = startDiff > 0 ? startDiff : 0;
      // From minutes to pixels:
      const left = shift * (elementWidth / 60);
      const timelineContainerWidth = (elementWidth / 2) * 48 * 2; // TODO: don't hardcode
      if (left + width > timelineContainerWidth) {
        finalWidth = timelineContainerWidth - left; // To prevent DetailedView allocations to overflow the right edge of the timeline
      }
      return {
        borderColor: getVisualColorTheme(workOrder.derivedStatus).color,
        width: finalWidth || 0,
        borderWidth: sidePanelOrder?.id === workOrder.id ? "4px" : "2px",
        boxShadow:
          sidePanelOrder?.id === workOrder.id
            ? "0px 8px 10px rgba(90, 90, 90, 0.14), 0px 3px 14px rgba(90, 90, 90, 0.12), 0px 4px 5px rgba(90, 90, 90, 0.2)"
            : "none",
        height: workOrder?.allocHeight,
        top: workOrder?.visualTop,
        left,
        pointerEvents:
          isDragging || needToMakeItemSecondary
            ? "none"
            : ("inherit" as React.CSSProperties["pointerEvents"]),
        color: isLoading ? "#C4C4C4" : "inherit",
      };
    }, [
      width,
      elementWidth,
      workOrder?.plannedStartDate,
      workOrder.derivedStatus,
      workOrder.id,
      workOrder?.allocHeight,
      workOrder?.visualTop,
      visibleStartDate,
      getVisualColorTheme,
      sidePanelOrder?.id,
      isDragging,
      needToMakeItemSecondary,
      isLoading,
    ]);

    useMemo(() => {
      if ((superLinking as SuperLinkTeam)?.team) {
        orderStyles.boxShadow = "0px 0px 6px 1px rgba(100, 100, 100, 1)";
      } else if (!sidePanelOrder?.id) {
        orderStyles.boxShadow = "none";
      }
    }, [orderStyles, sidePanelOrder?.id, superLinking]);

    // @TODO: check can the implementation from the vessels card be reused
    const wrapperStyles = useMemo(() => {
      const widthCSS = "fit-content";
      const flexDirection = "column";
      const alignItems = "";
      return {
        width: widthCSS,
        flexDirection,
        alignItems,
        display: "flex",
      };
    }, []);

    const orderContentWrapperStyles = {
      backgroundColor: isDragging
        ? "transparent"
        : getVisualColorTheme(workOrder.derivedStatus).background,
      width: Math.max(width - 3, 0),
    };

    const handleChangeModalDeleteState = () => {
      setIsModalDeleteVessel(!isModalDeleteVessel);
    };

    const closeAssignmentModal = () => {
      setStartAssignment(false);
    };

    const onAllocationClick = (e) => {
      if (isLoading) {
        return;
      }
      // Handle edge case. "onAllocationClick" gets triggered also when clicking away from the 3-dots menu, or click modal buttons
      if (
        e.target?.classList?.contains("popup-overlay") ||
        e.target?.classList?.value?.includes("modalButton") ||
        e.target?.classList?.value?.includes("konciv") ||
        isInsideModal(
          e.target,
          "MuiDialog-root",
          "MuiModal-root",
          "MuiPopper-root",
        )
      ) {
        return;
      }

      // setTimeout fixes the race condition in the click event handling that
      // It allows the previous active sidepanel to have time detect clickaway and close itself + saving data before opening this one
      setTimeout(() => {
        setSidePanelOrder({
          id: workOrder?.id,
        });
      }, 5);

      if ((superLinking as SuperLinkTeam)?.team) {
        setStartAssignment(true);
        disableSuperLinkTooltip();
      } else {
        // setTimeout fixes the race condition in the click event handling that
        // It allows the previous active sidepanel to have time detect clickaway and close itself + saving data before opening this one
        setTimeout(() => {
          setSidePanelOrder({
            id: workOrder?.id,
          });
        }, 5);
      }
    };

    const koncivTop =
      (workOrder?.normalizedTotalPipeLoad > 0 ? 2 : 0) +
      (workOrder?.normalizedTotalRegularLoad > 0 ? 2 : 0);

    // Display inline needed in subWoContainer or floating sticky labels floats badly
    return (
      <div className="subWoContainer" style={{ display: "inline" }}>
        {/*  It needs to be outside of tableOderContainer, or position:sticky won't work inside of a container with position: absolute */}
        <div
          className="floatingLabel"
          ref={ref}
          data-allocation-width={orderStyles.width - 30}
          data-allocation-offsetleft={orderStyles.left}
          style={{
            ...orderContentWrapperStyles,
            ...{
              top: orderStyles.top + 12 - koncivTop,
              left: orderStyles.left + 20,
              width: Math.max(orderStyles.width - 30, 20), // remove less pixels than vessels because there is no extra icons
            },
          }}
        >
          <div ref={orderInfo} style={wrapperStyles as React.CSSProperties}>
            {renderEventInfo(workOrder)}
          </div>
        </div>

        <div
          className={styles.tableOrderContainer}
          style={{
            marginLeft: position,
            top: orderStyles.top,
            height: orderStyles.height,
            left: orderStyles.left,
          }}
          tabIndex={0}
          role="button"
          onClick={onAllocationClick}
          onKeyDown={() => null}
        >
          <DialogModal
            isOpen={isModalDeleteVessel}
            onClose={handleChangeModalDeleteState}
          >
            <DeleteWorkOrderModal
              changeModalState={handleChangeModalDeleteState}
              orderId={workOrder.id}
            />
          </DialogModal>
          <DialogModal isOpen={startAssignment} onClose={closeAssignmentModal}>
            <AssignTeamModal
              isModalAssign={startAssignment}
              changeModalState={closeAssignmentModal}
              workOrders={[workOrder]}
              teamData={(superLinking as SuperLinkTeam)?.team}
            />
          </DialogModal>
          <OrderCardDraggableSides
            {...draggableProps}
            canDrag={!isLoading}
            parentItemType={draggableItemType}
            orderId={workOrder?.id}
            onDrop={onPullEnd}
            height={`${orderStyles.height}px`}
            orderRegularWidth={width}
            orderExpandedWidth={orderStyles.width as number}
            inDetailedView={inDetailedView}
          />
          <div
            className={`${styles.tableEvent} tableEvent`}
            style={{
              ...omit({ ...orderStyles }, ["top", "left"]),
              cursor: isLoading ? "progress" : "",
              opacity: isDragging ? "0.7" : "1",
              boxShadow: isDragging
                ? "inset 0px 0px 10px 0px rgba(0, 0, 0, 0.3)"
                : orderStyles.boxShadow,
              width: Math.max(orderStyles.width, 45),
              borderColor: width <= 0 ? "#DD877E" : orderStyles.borderColor,
            }}
            ref={drag}
          >
            <div style={{ ...orderContentWrapperStyles }}>
              {isLoading && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  style={{
                    zIndex: 999,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
              <WorkOrderActions order={workOrder} withGhostLink={false} />
            </div>
          </div>
        </div>
      </div>
    );
  },
);
export default memo(VesselSubWorkOrderAllocation);
