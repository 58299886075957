import { gql, useMutation, useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import React, { FC, useMemo, useState } from "react";

import CircularLoading from "@/components/common/circularLoading";
import { captureMessage } from "@/helpers/captureError";
import { normalizeAssignment } from "@/helpers/normalizeCapabilityRequirements";
import {
  OrderStatusColors,
  OrderStatusEnum,
  OrderType,
} from "@/helpers/OrderStatusColors";
import clockBig from "@/style/img/clockBig.svg";
// import syncIcon from "@/style/img/sync.svg";
import warningIcon from "@/style/img/warning.svg";
import { WorkOrderLineTypes } from "@/types";
import { useUserData } from "@/zustand/useSettingsStore";

import { customOrderLinesSort } from "../operationLines/OperationLinesList";
import TimestampRow, {
  CostingType,
  LaborCode,
  TimestampInInvoices,
} from "./InvoiceTableStep1Row";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  workOrderId: string;
  waitingReview: boolean;
  errorMessage: string;
  orderLines: WorkOrderLineTypes[];
  setOrderLines: (WorkOrderLineTypes) => void;
  goToStep: (number) => void;
};

const GET_ALL_COSTING_TYPES = gql`
  query getCostingTypes {
    costingTypes {
      id
      name
      description
    }
  }
`;

const GET_ALL_LABOR_CHARGE_CODES = gql`
  query getLaborChargeCodes {
    laborChargeCodes {
      id
      name
      description
    }
  }
`;

const APPROVE_INVOICE_STEP_1 = gql`
  mutation closeWorkOrder($workOrderNumber: String!, $issuedBy: String!) {
    closeWorkOrder(workOrderNumber: $workOrderNumber, issuedBy: $issuedBy)
  }
`;

const SectionComponent: React.FC<{
  data: WorkOrderLineTypes;
  handleRemoveTimestamp: (arg0: string) => void;
  allLaborCodes: LaborCode[];
  allCostingTypes: CostingType[];
}> = ({ data, handleRemoveTimestamp, allLaborCodes, allCostingTypes }) => {
  const capabilityRequirements = useMemo(
    () => normalizeAssignment(data.assignment),
    [data],
  );

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <div>{`${data.capability}  ${data?.description} ${
          capabilityRequirements?.requiresEquipmentOperator ? " + OPERATOR" : ""
        }`}</div>
        <div
          style={{
            backgroundColor: `${OrderStatusColors.getBackgroundColor(
              data.derivedStatus as OrderStatusEnum,
              OrderType.OPERATION_LINE,
            )}`,
          }}
          className={styles.operatorName}
        >
          {capabilityRequirements.requiresEquipmentOperator && (
            <>
              <span className={textStyles.primaryText}>
                {capabilityRequirements.equipment}
              </span>
              <span className={textStyles.primaryText}> + </span>
            </>
          )}
          <span
            style={
              capabilityRequirements.assigned === "Unassigned"
                ? { color: "#BB0000" }
                : {}
            }
            className={textStyles.primaryText}
          >
            {capabilityRequirements.assigned}
          </span>
        </div>
      </div>
      {!isEmpty(data?.timestampOperations) && (
        <div className={`${styles.gridRow} ${styles.gridRowStep1}`}>
          <div className={styles.timestampDates} style={{ marginLeft: "15px" }}>
            Timestamps
          </div>
          <div>Used labour</div>
          <div>Labor charge code</div>
          <div>Costing type</div>
          <div className={styles.deleteButton}>Delete</div>
        </div>
      )}
      {data?.timestampOperations?.map((row: TimestampInInvoices) => {
        return (
          <TimestampRow
            key={row.id}
            timestamp={row}
            allCostingTypes={allCostingTypes}
            allLaborCodes={allLaborCodes}
            operatorId={data.assignment?.operator?.id}
            removeTimestamp={handleRemoveTimestamp}
          />
        );
      })}
    </div>
  );
};

const InvoiceTableStep1: FC<Props> = ({
  workOrderId,
  goToStep,
  orderLines,
  setOrderLines,
  waitingReview,
  errorMessage,
}) => {
  const [allCostingTypes, setAllCostingTypes] = useState<CostingType[]>([]);
  const [allLaborCodes, setAllLaborCodes] = useState<LaborCode[]>([]);
  const [approveInvoice] = useMutation(APPROVE_INVOICE_STEP_1);
  const userData = useUserData();

  const handleRemoveTimestamp = (id: string) => {
    const newArr = orderLines.map((opLine) => {
      if (opLine?.timestampOperations?.some((el) => id === el.id)) {
        // Create a new array of timestamps excluding the one to be removed
        const newTimestamps = opLine.timestampOperations.filter(
          (el) => el.id !== id,
        );
        // Return a new opLine object with the updated timestamps array
        return { ...opLine, timestampOperations: newTimestamps };
      }
      // Return the opLine unmodified if the condition is not met
      return opLine;
    });
    setOrderLines(newArr);
  };

  const handleApproval = async () => {
    console.debug("approving", workOrderId, "with orderlines:", { orderLines });
    const { data } = await approveInvoice({
      variables: {
        workOrderNumber: workOrderId,
        issuedBy: userData.mail,
      },
    });

    if (!data?.closeWorkOrder?.length) {
      console.error(data);
      captureMessage(
        `error approving invoice of wo: ${workOrderId} | ${data?.closeWorkOrder}`,
      );
    }
    goToStep(2);
  };

  const { loading } = useQuery(GET_ALL_COSTING_TYPES, {
    onCompleted: (res) => {
      const parsed = res?.costingTypes?.map((item) => {
        return { value: item?.id, label: item?.description };
      });
      setAllCostingTypes(!isEmpty(parsed) ? parsed : []);
    },
  });

  useQuery(GET_ALL_LABOR_CHARGE_CODES, {
    onCompleted: (res) => {
      const parsed = res?.laborChargeCodes?.map((item) => {
        return { value: item?.id, label: item?.description };
      });
      setAllLaborCodes(!isEmpty(parsed) ? parsed : []);
    },
  });

  // this was here for MEC issues when UsedLabor = 0. Not anymore? (TODO: Remove)
  // const hasAnyUsedLaborHigherThan0 = !Array.isArray(orderLines)
  //   ? false
  //   : orderLines.every((o) =>
  //       o.timestampOperations?.every((t) => t?.usedLabor <= 0),
  //     );
  // console.log({ orderLines }, hasAnyUsedLaborHigherThan0);

  const sortedOrderLines = useMemo(() => {
    // Check if orderLines is iterable
    if (!Array.isArray(orderLines)) {
      // Return an empty array or handle the case as appropriate
      return [];
    }
    // Sort the operation lines using the custom comparator
    // Create a new array from orderLines because sometimes could complain about "read-only" array
    return [...orderLines].sort(customOrderLinesSort);
  }, [orderLines]);

  if (loading) {
    return (
      <span style={{ position: "relative", padding: "200px" }}>
        <CircularLoading />
      </span>
    );
  }
  return (
    <div className={styles.tableStep1}>
      {waitingReview && (
        <div className={styles.overlayLoading}>
          <img src={clockBig} alt="loading" />
          <span>Syncing invoice data with M3</span>
          <div />
        </div>
      )}
      {errorMessage && (
        <div className={`${styles.warningArea}`}>
          <img
            src={warningIcon}
            alt="Warning"
            style={{
              margin: "4px 15px -5px 3px",
              color: "black",
              borderColor: "#00ADBB",
            }}
          />
          {errorMessage}
        </div>
      )}
      {/* 
      <div
        className={styles.warningArea}
        style={{
          backgroundColor: "#BFEAEE",
        }}
      >
        <img
          src={syncIcon}
          alt="Syncing"
          style={{
            margin: "4px 15px -5px 3px",
            color: "black",
            borderColor: "#BFEAEE", // : "#E6D2D1",
          }}
        />
        Some data hasen’t been synced. Please review and APPROVE again.
      </div> */}
      {sortedOrderLines
        ?.filter((section) => {
          return section?.timestampOperations?.[0];
        })
        .map((section) => (
          <SectionComponent
            key={workOrderId + section.id}
            handleRemoveTimestamp={handleRemoveTimestamp}
            allLaborCodes={allLaborCodes}
            allCostingTypes={allCostingTypes}
            data={section}
          />
        ))}
      <br />
      <div
        className={`${styles.wizardButtonSection}`}
        style={{
          justifyContent: "flex-end",
        }}
      >
        <div>
          <button
            className={`${textStyles.primaryText} ${styles.invoiceWizardButton} `}
            type="button"
            onClick={handleApproval}
            title="Approve and go to next step: review invoice"
          >
            Approve
          </button>
          <div
            style={{
              verticalAlign: "text-top",
              lineHeight: "1.1em",
              width: "450px",
            }}
          >
            <img
              src={warningIcon}
              alt="Warning"
              style={{
                margin: "4px 10px 0px 3px",
                color: "black",
                borderColor: "#00ADBB",
              }}
            />
            <span
              className={textStyles.secondaryText}
              style={{
                paddingBottom: 40,
              }}
            >
              You will not be able to return to edit REVIEW ORDER step until the
              whole invoicing process is approved and completed
            </span>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTableStep1;
